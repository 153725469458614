import React, { useRef, useState, useEffect } from "react"
import { scrollTo } from "gatsby-plugin-smoothscroll"

const HorizontalCards = ({ Card, items }) => {
	const [currentIndex, setCurrentIndex] = useState(0)
	const [totalDots, setTotalDots] = useState(0)
	const [visibleCards, setVisibleCards] = useState(1)
	const cardContainerRef = useRef(null)

	const updateVisibleCards = () => {
		const cardContainer = cardContainerRef.current
		const cardWidth = cardContainer.firstChild.offsetWidth + 10
		const containerWidth = cardContainer.offsetWidth
		const visibleCardsCount = Math.floor(containerWidth / cardWidth) || 1
		setVisibleCards(visibleCardsCount)
	}

	const scrollToCard = index => {
		const cardContainer = cardContainerRef.current
		const cardWidth = cardContainer.firstChild.offsetWidth + 10

		cardContainer.scrollTo({
			left: cardWidth * index * visibleCards,
			behavior: "smooth",
		})
	}

	const handleScroll = () => {
		const cardContainer = cardContainerRef.current
		const cardWidth = cardContainer.firstChild.offsetWidth + 10
		const scrollPosition = cardContainer.scrollLeft

		let newIndex = cardContainer.scrollLeft / (cardWidth * visibleCards)

		setCurrentIndex(prevIndex => Math.ceil(newIndex))
	}

	const handleScrollEnd = () => {}

	useEffect(() => {
		const cardContainer = cardContainerRef.current
		updateVisibleCards()
		cardContainer.addEventListener("scroll", handleScroll)
		cardContainer.addEventListener("scrollend", handleScrollEnd)
		window.addEventListener("resize", updateVisibleCards)

		setCurrentIndex(prevIndex => Math.floor(prevIndex / visibleCards) * visibleCards)

		// console.log(`Number of cards: ${items.length}`)
		// console.log(`Fully visible cards: ${visibleCards}`)

		setTotalDots(prevTotalDots => Math.floor(items.length / visibleCards))

		return () => {
			cardContainer.removeEventListener("scroll", handleScroll)
			cardContainer.removeEventListener("scrollend", handleScrollEnd)
			window.removeEventListener("resize", updateVisibleCards)
		}
	}, [visibleCards])

	if (items.length === 0) {
		return null
	}

	return (
		<div className="lg:mx-0 overflow-hidden -mx-[20px]">
			<div
				ref={cardContainerRef}
				className="lg:grid lg:gap-[30px] lg:grid-cols-2 flex flex-row overflow-auto no-scrollbar space-x-20 lg:space-x-0 px-[20px] lg:px-0">
				{items.map((card, index) => (
					<Card key={index} {...card} />
				))}
			</div>
			{/* Dot navigation for desktop */}
			<div className="flex lg:hidden mt-20 space-x-10 items-center justify-center">
				{Array.from({ length: totalDots }).map((_, index) => (
					<button
						key={index}
						className={`w-[10px] h-[10px] rounded-full transform duration-300 outline-none ${
							index === currentIndex ? "bg-pictonblue" : "bg-silversand"
						}`}
						onClick={() => {
							scrollToCard(index)
							setCurrentIndex(index)
						}}></button>
				))}
			</div>
		</div>
	)
}

export default HorizontalCards
