import React, { useRef, useEffect } from "react"

const InlineVideo = ({ children, threshold = 0.5 }) => {
	const videoRef = useRef(null)

	useEffect(() => {
		const video = videoRef.current

		const handlePlayPause = entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting && !document.hidden) {
					video.play()
				} else {
					video.pause()
				}
			})
		}

		const observer = new IntersectionObserver(handlePlayPause, {
			threshold,
		})

		observer.observe(video)

		const handleVisibilityChange = () => {
			if (document.hidden) {
				video.pause()
			} else if (
				videoRef.current &&
				videoRef.current.getBoundingClientRect().top < window.innerHeight
			) {
				video.play()
			}
		}

		document.addEventListener("visibilitychange", handleVisibilityChange)

		return () => {
			observer.unobserve(video)
			document.removeEventListener("visibilitychange", handleVisibilityChange)
		}
	}, [])

	// poster={poster}
	return (
		<video
			className="w-full h-full object-cover"
			ref={videoRef}
			preload="auto"
			muted
			loop
			controls={false}
			autoPlay={false}
			playsInline>
			{children}
		</video>
	)
}

export default InlineVideo
