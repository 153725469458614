import React from "react"

import { graphql, navigate, Link } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"

import Seo from "../components/Seo"
import Layout from "../components/Layout"

import Stage from "../components/Stage"

import Grid from "../components/Grid"
import Container from "../components/Container"
import { BlueBanner } from "../components/Banner"
import { Button } from "../components/Button"
import ArticlePreview from "../components/ArticlePreview"

import CustomerCarousel from "../components/CustomerCarousel"
import HorizontalCards from "../components/HorizontalCards"

import InlineVideo from "../components/InlineVideo"
import ModalVideo from "../components/ModalVideo"
import Teaser from "../components/Teaser"

import AiFeaturesMp4Video from "../videos/cfm-ai-features.mp4"
import AiFeaturesPreviewMp4Video from "../videos/cfm-ai-features-preview.mp4"
import AiFeaturesPreviewWebMVideo from "../videos/cfm-ai-features-preview.webm"
import AiFeaturesPosterImage from "../videos/cfm-ai-features-poster.jpg"

import AiFeatureSeafarerDetailsMp4Video from "../videos/cfm-ai-feature-seafarer-details.mp4"
import AiFeatureProceduresTranslationMp4Video from "../videos/cfm-ai-feature-procedures-translation.mp4"
import AiFeatureNewsPostMp4Video from "../videos/cfm-ai-feature-news-post.mp4"
import AiFeatureManualSearchMp4Video from "../videos/cfm-ai-feature-manual-search.mp4"

import AiFeatureSeafarerDetailsWebMVideo from "../videos/cfm-ai-feature-seafarer-details.webm"
import AiFeatureProceduresTranslationWebMVideo from "../videos/cfm-ai-feature-procedures-translation.webm"
import AiFeatureNewsPostWebMVideo from "../videos/cfm-ai-feature-news-post.webm"
import AiFeatureManualSearchWebMVideo from "../videos/cfm-ai-feature-manual-search.webm"

import { RocketIcon, PaperCheckIcon, DepartmentIcon, AIIcon } from "../components/SVGIcons"

import { ScheduleDemoAlternativeForm } from "../components/ScheduleDemoForm"

const features = [
	{
		title: "Streamline communication with AI",
		text: "Effortlessly create internal posts for your teams with AI-generated content. Simply input a prompt, and let the system generate clear, consistent, and engaging internal communications in seconds. This saves valuable time and ensures uniform messaging across your organization, helping you keep everyone informed and aligned.",
		video: (
			<InlineVideo threshold={0.3}>
				<source src={AiFeatureNewsPostWebMVideo} type="video/webm; codecs=vp9,vorbis" />
				<source src={AiFeatureNewsPostMp4Video} type="video/mp4" />
			</InlineVideo>
		),
	},
	{
		title: "Fast-track crew profile creation",
		text: "Upload seafarer documents, and our AI automatically extracts and organizes critical data to create accurate crew profiles. This eliminates manual data entry, speeds up onboarding, and ensures all necessary qualifications and certifications are properly tracked. Focus on managing your crew, not paperwork.",
		video: (
			<InlineVideo threshold={0.3}>
				<source
					src={AiFeatureSeafarerDetailsWebMVideo}
					type="video/webm; codecs=vp9,vorbis"
				/>
				<source src={AiFeatureSeafarerDetailsMp4Video} type="video/mp4" />
			</InlineVideo>
		),
	},
	{
		title: "Ensure compliance with AI assistance",
		text: "Automatically summarize and translate complex operational procedures into multiple languages. This feature highlights important updates and helps ensure that your teams, regardless of location, have the information they need to follow the latest safety and compliance protocols efficiently and accurately.",
		video: (
			<InlineVideo threshold={0.3}>
				<source
					src={AiFeatureProceduresTranslationWebMVideo}
					type="video/webm; codecs=vp9,vorbis"
				/>
				<source src={AiFeatureProceduresTranslationMp4Video} type="video/mp4" />
			</InlineVideo>
		),
	},
	{
		title: "Get instant answers across all modules with AI",
		text: "AI delivers fast, accurate responses to queries by searching through all CFM manuals. This ensures you can access critical information quickly and make informed decisions without spending time combing through extensive documentation, improving operational efficiency.",
		video: (
			<InlineVideo threshold={0.3}>
				<source src={AiFeatureManualSearchWebMVideo} type="video/webm; codecs=vp9,vorbis" />
				<source src={AiFeatureManualSearchMp4Video} type="video/mp4" />
			</InlineVideo>
		),
	},
]

const AiPage = ({
	data: { blueBannerImage, teaserImage, ogImage, relatedArticles },
	cta = "Schedule a demo",
	children,
	className = "",
	onSubmitHandler,
	initialValues = {},
}) => {
	return (
		<Layout translucent={true} hideTopBanner={true}>
			<Seo
				title="Boost Fleet Efficiency with AI in Cloud Fleet Manager"
				description="Discover the power of AI for ship management. Our AI-driven tools help you save time, reduce costs, and enhance operational efficiency."
				keywords="CFM AI, Cloud Fleet Manager AI, AI-powered ship management, AI for fleet management, AI automation in shipping, Ship management software with AI, AI-driven fleet operations, Optimize fleet with AI, Fleet management software, AI-enhanced decision-making, Maritime compliance with AI, AI crewing solutions, Ship management automation, Save time with AI in shipping, AI for maritime industry, Future of fleet management"
				ogImage={ogImage}
			/>

			<Stage name="hero" className="overflow-visible">
				<div className="xl:container px-10 xl:px-30">
					<div className="px-10 md:px-20">
						<div className="pt-[140px] grid grid-cols-12 gap-x-20 md:gap-x-40 gap-y-50 relative">
							<div className="col-span-12 md:col-span-10 md:col-start-2 xl:col-span-10 xl:col-start-2 flex flex-col items-center lg:items-start xl:mr-30 self-center">
								<h1 className="display1 text-white text-center self-center xl:pr-30 md:mt-[50px]">
									Cloud Fleet Manager with AI – smarter ship management starts
									here
								</h1>
								<h3 className="display3 reg text-white text-center self-center mt-20 mb-60">
									Discover how our AI-aided features help you save time, reduce
									costs, and improve operations with every decision.
								</h3>
								<ModalVideo
									event="video-discover-our-new-ai-features"
									className="-mb-[100px] md:-mb-[120px] xl:-mb-[160px]"
									poster={AiFeaturesPosterImage}
									rootElement="modal-root"
									previewSources={[
										{
											src: AiFeaturesPreviewMp4Video,
											type: "video/mp4",
										},
										{
											src: AiFeaturesPreviewWebMVideo,
											type: "video/webm; codecs=vp9,vorbis",
										},
									]}
									sources={[
										{
											src: AiFeaturesMp4Video,
											type: "video/mp4",
										},
									]}
								/>
							</div>
						</div>
					</div>
				</div>
			</Stage>

			<div className="flex flex-col gap-y-80 md:gap-y-100 lg:gap-y-120 pb-spacer-xl">
				<Container className="mt-spacer-md pt-[100px] md:pt-[120px] xl:pt-[160px]">
					<div className="h-[50px] flex items-center">
						<CustomerCarousel className="w-full" />
					</div>
				</Container>

				<Container>
					<div className="flex flex-col gap-y-80 md:gap-y-100 lg:gap-y-120">
						{features.map(({ title, text, video }, index) => (
							<div
								className="flex flex-col xl:flex-row gap-20 md:gap-40 group"
								key={`feature-${index}`}>
								<div className="w-full xl:w-1/2 group-even:xl:order-last">
									<div className="flex flex-col h-full justify-center">
										<div className="w-full aspect-w-16 aspect-h-9 rounded-medium overflow-hidden shadow-video bg-tealblue">
											{video}
										</div>
									</div>
								</div>
								<div className="w-full xl:w-1/2">
									<div className="flex flex-col gap-y-20 h-full justify-center">
										<h1 className="display1">{title}</h1>
										<p>{text}</p>
									</div>
								</div>
							</div>
						))}
					</div>
				</Container>

				<Grid className="">
					<div className="relative col-span-12 xl:col-span-10 xl:col-start-2">
						<div className="flex flex-col gap-y-10 mb-spacer-lg">
							<h1 className="display1 text-left xl:text-center">
								Unlock the potential of AI in{" "}
								<span className="whitespace-nowrap">ship management</span>
							</h1>
							<p className="mt-10 text-left xl:text-center">
								Artificial intelligence isn’t just a buzzword—it’s a game-changer
								for shipping companies. Cloud Fleet Manager is designed to boost
								operational efficiency, decision-making, and collaboration. By
								integrating AI, we further enhance these capabilities, giving you a
								smarter, more efficient way to manage ship operations, empowering
								your teams to work smarter, not harder.
							</p>
						</div>

						<HorizontalCards
							Card={({ bgColor, icon, title, text }) => (
								<div
									className={`p-20 lg:p-30 ${bgColor} bg-opacity-30 rounded-medium min-w-[280px] lg:min-w-fit hover:cursor-pointer lg:hover:cursor-default`}>
									{icon}
									<h3 className="mt-30 display3">{title}</h3>
									<p className="mt-12">{text}</p>
								</div>
							)}
							items={[
								{
									bgColor: "bg-[#CBE7FB]",
									icon: <RocketIcon />,
									title: "Faster, smarter operations",
									text: "Automate routine tasks in Cloud Fleet Manager and make faster decisions with AI-assisted processes that free up time for what really matters.",
								},
								{
									bgColor: "bg-[#FFE7B3]",
									icon: <PaperCheckIcon />,
									title: "Streamlined compliance support",
									text: "AI helps your teams stay on top of compliance by making it easier to access and act on the latest regulations, ensuring smooth operations.",
								},
								{
									bgColor: "bg-[#DCEDC8]",
									icon: <DepartmentIcon />,
									title: "Improved collaboration",
									text: "AI connects your teams by delivering clear, consistent information across your entire organization, no matter where they are.",
								},
								{
									bgColor: "bg-[#BED4E5]",
									icon: <AIIcon />,
									title: "Future-ready innovation",
									text: "With CFM’s evolving AI-assisted capabilities, your business is equipped to meet the challenges of today and ready to scale for the future.",
								},
							]}
						/>
					</div>
				</Grid>

				<div className="bg-porcelain py-spacer-xl">
					<ScheduleDemoAlternativeForm
						initialValues={{
							campaign: "discover_our_new_ai_features",
						}}
						onSubmitHandler={() => {
							navigate("/demo/thank-you")
						}}>
						<p>
							To guarantee the best customer experience, one of our local
							representatives will get in touch within three working days to assess
							your company’s current situation and requirements. Additional and more
							in-depth information will be provided. After identifying the solutions
							relevant to you, an onsite or remote demo will be scheduled to give you
							an understanding of what Cloud Fleet Manager can do for your company.
						</p>
					</ScheduleDemoAlternativeForm>
				</div>

				{/* 
				<Teaser image={getImage(teaserImage)}>
					<div className="flex flex-col justify-center h-full">
						<div className="bg-porcelain rounded-medium p-40 flex flex-col justify-start items-start gap-30">
							<div className="flex flex-col justify-start items-start gap-12">
								<div className="self-stretch text-tealblue text-sm font-bold uppercase leading-18 tracking-wider">
									Webinar
								</div>
								<h2 className="self-stretch display2">
									Enhance Fleet Management with AI
								</h2>
								<div className="self-stretch text-jetblack text-base font-bold font-['Roboto'] leading-7">
									Thursday, Nov. 14 &bull; 11:00 CET
								</div>
								<div className="self-stretch text-jetblack text-base font-normal font-['Roboto'] leading-7">
									Discover how AI-enabled features is changing the way ship
									managers handle data, compliance, and operations. Join our
									upcoming webinar to learn about the latest advancements in Cloud
									Fleet Manager and how AI can help you make smarter decisions,
									improve efficiency, and streamline your fleet operations.
								</div>
							</div>
							<a href="#">
								<Button
									primary={false}
									data-umami-event="click-register-for-ai-webinar">
									Register for the Webinar
								</Button>
							</a>
						</div>
					</div>
				</Teaser>
				*/}

				{relatedArticles.nodes.length > 0 && (
					<Container>
						<div className="flex flex-col gap-y-[60px]">
							<h1 className="display1">
								More about AI in{" "}
								<span className="whitespace-nowrap">ship management</span>
							</h1>
							<div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-20 md:gap-40">
								{relatedArticles.nodes.map(article => (
									<Link
										key={`article-${article.id}`}
										to={`/blog/${article.slug}`}
										className="group flex flex-col gap-y-20">
										<div className="w-full rounded-medium overflow-hidden relative hover:cursor-pointer">
											<GatsbyImage
												className="rounded-medium w-full overflow-hidden"
												imgClassName="overflow-hidden rounded-medium "
												objectFit="cover"
												loading="eager"
												objectPosition="center center"
												image={
													article.teaser_image.imgixImage.gatsbyImageData
												}
												alt={article.title}
												title={article.title}
											/>
										</div>

										{/* <div className="w-full aspect-w-16 aspect-h-9 rounded-medium overflow-hidden relative">
										<Link
											to="/ai"
											data-umami-event="click-learn-more-about-ai-features">
											<StaticImage
												src="../images/ai-manual-search-preview.png"
												alt="Cloud Fleet Manager AI Manual Search Preview"
												title="Cloud Fleet Manager AI Manual Search Preview"
												className="hover:cursor-pointer duration-500 hover:scale-125 z-0 object-cover object-center"
											/>
										</Link>
									</div> */}

										<div>
											<ArticlePreview
												variant="snippet"
												date={article.published_at}
												title={article.title}
												excerpt={article.excerpt}
											/>
										</div>
									</Link>
								))}
							</div>
						</div>
					</Container>
				)}

				<Container>
					<p className="col-start-2 col-span-10 text-left font-bold text-sm">
						Hanseaticsoft GmbH is part of a global professional services group
						specialising in engineering and technology for the maritime industry.
						Hanseaticsoft GmbH, its affiliates and subsidiaries and their respective
						officers, employees or agents are individually and collectively, referred to
						in this disclaimer as ‘Lloyd’s Register’. To the extent permissible under
						law, Lloyd’s Register assumes no responsibility and shall not be liable to
						any person for any loss, damage or expense caused by reliance on the
						information or advice on this website or howsoever provided, unless that
						person has signed a contract with the relevant Lloyd’s Register entity for
						the provision of this information or advice and in that case any
						responsibility or liability is exclusively on the terms and conditions set
						out in that contract. The content on our site is provided for general
						information only. It is not intended to amount to advice on which you should
						rely. You must obtain professional or specialist advice before taking, or
						refraining from, any action on the basis of the content on our site.
						Although we make reasonable efforts to update the information on our site,
						we make no representations, warranties or guarantees, whether express or
						implied, that the content on our site is accurate, complete or up to date
						and the availability of any products, features or functionality remains at
						the sole discretion of Lloyd's Register.
					</p>
				</Container>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query {
		blueBannerImage: file(relativePath: { eq: "portal-2-dashboard.png" }) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED, width: 760)
			}
		}
		teaserImage: file(relativePath: { eq: "ai-webinar.jpg" }) {
			childImageSharp {
				gatsbyImageData(layout: CONSTRAINED, width: 760)
			}
		}
		ogImage: file(
			relativePath: { eq: "og-discover-ai-driven-features-for-ship-managers.png" }
		) {
			childImageSharp {
				gatsbyImageData(layout: FIXED, width: 1200, height: 630, formats: [JPG])
			}
		}
		relatedArticles: allMongodbBloghanseaticsoftArticles(
			filter: { isFuture: { eq: false }, tags: { elemMatch: { name: { in: ["AI"] } } } }
			sort: { order: DESC, fields: published_at }
			limit: 3
		) {
			nodes {
				title
				excerpt
				published_at(formatString: "MMMM DD, YYYY")
				slug
				teaser_image {
					imgixImage {
						gatsbyImageData(layout: CONSTRAINED, width: 540, height: 370)
					}
				}
			}
		}
	}
`

export default AiPage
